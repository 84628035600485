import { Product } from "~/models/product.model";
import { useCategoriesStore } from "~/store/categoriesStore";

export const getUrl = (name, params = {}) => {
  const route = useRouter().resolve({ name, params });

  return useRuntimeConfig().public.appUrl + route.fullPath;
};

export const configureProductSeo = (product, pattern) => {
  const route = useRoute();
  const config = useRuntimeConfig();

  const getMeta = (type) => {
    if (true === pattern.useCustomMeta && product.hasContent(type)) {
      return product.getContent(type);
    }

    return cleanStringsConcat([
      pattern[type].prefix?.(),
      product.getName(),
      pattern[type].postfix?.(),
    ]);
  };

  useHead({
    meta: [
      {
        property: "product:price:amount",
        content: product.getPrice(Product.PRICE_TYPE.CURRENT),
      },
      {
        property: "product:price:currency",
        content: product.getCurrency(Product.PRICE_TYPE.CURRENT),
      },
    ],
  });

  useSeoMeta({
    title: () => getMeta("metaTitle"),
    description: () => getMeta("metaDescription"),
    keywords: () => getMeta("metaKeywords"),
    ogType: "product",
    ogTitle: () => product.getName(),
    ogImageUrl: () => product.getMainImage("large"),
    ogUrl: () => config.public.appUrl + route.path,
  });
};

export const configureCategorySeo = (category) => {
  const route = useRoute();
  const config = useRuntimeConfig();

  const categoryStore = useCategoriesStore();
  const { getCategoryContent } = storeToRefs(categoryStore);

  const patternSeo = {
    metaTitle: [_T("@Buy in Ukraine")],
    metaDescription: [
      _T("@From the manufacturer"),
      _T("@Buy at a good price with a guarantee"),
      _T("@Delivery throughout Ukraine"),
    ],
    metaKeywords: [],
  };

  const getMeta = (type) => {
    return (
      getCategoryContent.value(category, type) ??
      cleanStringsConcat([category.getName(), patternSeo[type]])
    );
  };

  useSeoMeta({
    title: () => getMeta("metaTitle"),
    description: () => getMeta("metaDescription"),
    keywords: () => getMeta("metaKeywords"),
    ogTitle: () => category.getName(),
    ogImageUrl: () => category.getImageUrl("tile", false),
    ogUrl: () => config.public.appUrl + route.path,
  });
};
